div{
  font-family: 'Times New Roman', Times, serif;
}

.cart{
  position: absolute;
  right: 35px;
  top: 100px;
  z-index: 999;
  background-color: white;
}
  #background{
    background-color: rgb(246,246,246);
  }
  #catchy{
    margin:auto;
  }

  #open-shoe{
    
    color: rgb(144, 24, 255);
    text-shadow: 2px 2px 1px rgb(217, 188, 27);
  }

  #open-shoes{
    
    color: gray;
  }

  #left-pics{
    max-width: 250px;
  }

  #middle-pics{
    max-width: 800px;
  }
  
  #buy-btn{
    
    background-color: rgb(217, 188, 27);
    color: white;
    transition: all 0.5s;
  }

  #buy-btn:hover{
    transform: scale(1.1);
  }

  #product-card{
    transition: all 0.5s;
    border: none;
   
  }

  #product-card:hover{
    transform: scale(1.01);
  }

  #card-body{
    background-color: rgb(246,246,246);
  }

  #quantity{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  #quant-btn{
    width: 40px;
    height: 50px;
    display: flex;
    display: inline-block;
    align-items: center;
    justify-content: center;
    border: none;
    transition: all 0.5s;
    background-color: rgb(246,246,246);
  }

  #price{
    color: rgb(217, 188, 27);
  }

  #cart-btn{
    background-color: rgb(217, 188, 27);
    color: white;
    width: 300px; 
    transition: all 0.5s;
  }

  #cart-btn:hover{
    transform: scale(1.1);
    color: rgb(144, 24, 255);
  }
  

 