body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./Photos/AdobeStock_449117631.jpeg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  height: 100vh;
  width: 100%;
}

/*HEADER*/
.navbar{
  background-color: rgb(144, 24, 255, 0.5);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#cart{
  max-width: 2.5vw;
}

#lists{
  color: white;

}



/*Foot*/
.foot{
  color: rgb(144, 24, 255);
  font-family: 'Times New Roman', Times, serif;
  text-decoration: none;
}

.hover{
  width: 50px;
  transition: all 0.3s;
}

.hover:hover {
  transform: scale(1.15);
}

.foot-hover{
  text-decoration: none;
  color: rgb(144, 24, 255);
  transition: all 0.3s;
}

#designed{
  color: rgb(144, 24, 255);
}

.foot-hover:hover{
  transform: scale(1.15);
  color: rgb(144,24,255);
  text-shadow: 2px 2px 4px rgb(255, 221, 29);
}

.adjust{
  max-width: 50px;
}

@media  screen and (max-width: 768px) {
  #cart{
    max-width: 5vw;
  }
  #logo{
    max-width: 70px;
  }

  
  .foot-hover{
    font-size: 3vw;
  }
}
