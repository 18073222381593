.image {
  position: relative;
}

.second-word {
  color: rgb(217, 188, 27);
  font-family: "Times New Roman", Times, serif;
}

.athlete-stats {
  background: linear-gradient(to bottom, rgb(144, 24, 255), gray);

  border-radius: 1%;
}

.content:hover {
  opacity: 1;
}

#random {
  background-image: url("../../../Photos/Opening-Picture.webp");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  transition: 5s;
  animation: animate 5s infinite;
  animation-direction: alternate-reverse;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

#name{
  border: 3px;
  border-style: solid;
  border-color: rgb(255, 221, 29);
}

#image{
  min-width: 300px;
  max-width: 300px;
}

#para{
  border: 3px;
  border-style: solid;
  border-color: rgb(255, 221, 29);
  color: white;
  font-family: "Times New Roman", Times, Baskerville, Georgia, serif;
}

#athete-words{
  color: white;
  font-family: "Times New Roman", Times, serif;
  font-size: large;
}

#images{
  border: 3px;
  border-style: solid;
  border-color: rgb(255, 221, 29);
}

#distance {
  margin: 0 0 0 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  color: rgb(255, 221, 29);
  text-shadow: 2px 2px 4px rgb(144, 24, 255);
  font-family: "Times New Roman", Times, serif;
}

#championsss {
  color: rgb(217, 188, 27);
  text-shadow: 1px 1px 2px rgb(144, 24, 255);
  font-family: "Times New Roman", Times, serif;
}

#personal-stats {
  font-family: "Times New Roman", Times, serif;
  color: white;
}

#events {
  color: rgb(217, 188, 27);
  font-family: "Times New Roman", Times, serif;
  text-shadow: 1px 1px 2px rgba(187, 154, 33, 0.7);
}

#stats {
  margin-top: 10px;
  color: rgb(217, 188, 27);
  text-shadow: 1px 1px 2px rgb(144, 24, 255);
  font-family: "Times New Roman", Times, serif;
}

@keyframes animate {
  0%, 100% {
    background-image: url("../../../Photos/Jump\ Squad.webp");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
  }
  25% {
    background-image: url("../../../Photos/Throw\ Squad.webp");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
  }
  50% {
    background-image: url("../../../Photos/Distance.webp");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
  }
  75% {
    background-image: url("../../../Photos/Racewalk.webp");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
  }

}
