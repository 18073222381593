#purp-back{
    background-image: url('../../../Photos/AdobeStock_449117631.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    height: 100%;
    width: 100%;
    color: white;
  }

  #test{
    height: 40vh;
    max-width: 700px;
    object-fit: cover;
   }

 #parent{
  max-width: 100%;
  max-height: 2px;
 }

   #champions {
    color: rgb(217, 188, 27);;
    text-shadow: 1px 1px 2px rgb(144, 24, 255);
    font-family: 'Times New Roman', Times, serif;
  }

  #champions-sm{
    margin-top: 20px;
    color: rgb(217, 188, 27);;
    text-shadow: 1px 1px 2px rgb(144, 24, 255);
    font-family: 'Times New Roman', Times, serif;
  }

  #words {
    padding-left: 2%;
    color: rgb(144, 24, 255);
    font-family: 'Times New Roman', Times, serif;
  }

  #words-sm{
    font-size: 3vw;
    padding-left: 2%;
    color: rgb(144, 24, 255);
    font-family: 'Times New Roman', Times, serif;
  }

  #championss{
    margin-top: 50px;
    padding-left: 2%;
    color: rgb(217, 188, 27);;
    text-shadow: 1px 1px 2px rgb(144, 24, 255);
    font-family: 'Times New Roman', Times, serif;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
 #test{
  height: 15vh;
  max-width: 800px;
  object-fit: cover;
 }
}

@media screen and (max-width: 767px) {
  #test{
    height: 15vh;
    max-width: 800px;
    object-fit: cover;
   }
}