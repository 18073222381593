#border{
    width: 100%;
    border: 5px solid rgb(217, 188, 27);
    overflow: hidden;
}

#ratio{
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/12;
}

